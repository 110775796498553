
function ContractBox({title, icon, txt}) {
    return (
        <div className="xl:w-1/2 w-full  mb-20 h-[600px] mx-5 flex justify-center items-start flex-col">
            <div className="flex items-center justify-center mb-2 ml-3">
                <i className={`${icon} text-2xl relative -top-0.5 mr-2`}></i>
                <p>{title}</p>
            </div>
            <div className="h-full w-full bg-[#B3EAFF] border-2 border-[#6BD6FF] p-2 rounded-3xl " style={{ boxShadow: 'inset 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 -4px 6px rgba(0, 0, 0, 0.1)' }}>
                {txt}

            </div>
        </div>
    )
}

export default ContractBox 