
import ContractBox from "./ContractBox"
import { useInView } from 'react-intersection-observer'

function Contract({ name, changeStatus }) {

    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    const handleChange = (event) => {
        changeStatus(event.target.checked)
    }

    return (
        <div className="overflow-x-hidden">
            <div
                ref={ref}
                name={name}
                className={`bg-[#f4f4f4] h-auto flex justify-center items-center flex-col pt-20 md:px-10 px-5 py-20 relative transition-all duration-1000 ease-in-out ${inView ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full'}`}
            >
                <div className="flex justify-center xl:flex-row flex-col items-center w-full">
                    <ContractBox

                        title="License Terms"
                        icon="bi bi-vector-pen"
                        txt={

                            <div className="overflow-auto h-full p-5">
                                <p className="text-right">
                                    Date updated: 14 Jul 2023
                                </p>
                                <br />
                                <p>
                                    "Software" refers to any software developed by KaslaanSoft, and whether it is full or trial version. "User" or "You" refers to the person who is using the software, who is either owner of the software or using it in shared or someone else computer. "Developer" refers to KaslaanSoft software brand.
                                </p>
                                <br />
                                <p>
                                    If you are legally a child, then you acknowledge that your parent or legal guardian takes responsibility of your usage of any products developed by KaslaanSoft. And all the license terms bind to him or her.
                                </p>
                                <br />
                                <p>
                                    You are granted to use any software developed by KaslaanSoft for any purpose, whether its in business, education, office domain or any other place of usage. Usage permission is granted to every user of the computer in which the software in installed or present. Any output data, or outcome, you derive by any software can be used anywhere; but if encountered any harm, problem, loss etc, developer of the software shall not be held responsible.
                                </p>
                                <p>
                                    <br />
                                    Software as a whole or a part of shall not be distributed in any way and for any purpose. People should only be given the address where you bought or downloaded the software. Advertising the software is permitted on the condition that anything spoken about it is not false. Advertising is permitted for any purpose, whether its for user's itself benefit (in any way) or its for developer's benefit; as long as software is not copied to someone else computer in the process.
                                </p>
                                <p>
                                    No software will use internet network for any type of requirement without notifying user. Developer will never receive usage or any other information in any way, unless user wants to verify their software installation to receive future versions of software, when available, free of cost.
                                </p>
                                <br />
                                <p>
                                    Software Install ID acquired from any full version software should not be shared with anyone, whether it is an individual or a party. And no reason or cause would be accepted. Software Install ID once sent to verify@kaslaansoft.com, is stored in a database, by the Developer, along with the email address, from which it is received. It will store Software name as well, from which Software Install ID is acquired. Future versions of the software will be delivered to that email address for free, once available or released. User can request the Developer to delete permanently the record stored for him. Software Install ID may serve no purpose, if the software is purchased from Microsoft Store, since they upgrade the software by itself or prompts the user, directly, without any need of Software Install ID. But there is a possibility that any new version of the software could be removed from Microsoft App Store.
                                </p>
                                <br />
                                <p>
                                    Full version software can be used for unlimited time without any expiry date. And without any other restrictions found in trial versions.
                                </p>
                                <br />
                                <h2 className="font-medium">Refund</h2>
                                <br />
                                <p>
                                    As for refund policy, if software is purchased at any third-party stores, such as Microsoft Store; their own refund policies will be applied in your case. You cannot request then any refunds from the Developer.
                                </p>
                                <br />
                                <p>
                                    Users are advised to download and evaluate trial version of the software, before purchasing. Trial version is availed at no cost. And may contain one more features restriction. And may run only for a definite time or a definite number of times.
                                </p>
                                <br />
                                <p>
                                    If purchased software fails to perform a certain feature, described in software description, user could easily request refund by contacting the Developer. User could also request refund, if he experiences any flaws, errors, glitches, etc. We will issue 100% refund of the user spend amount, provided following criteria are met:
                                </p>
                                <br />
                                <ul className="list-disc pl-10">
                                    <li>User used trial version prior to purchasing the full version. But any complain he grounds for refund, was not present in trial version.</li>
                                    <li>User has a clear description of what went wrong. We may ask for any further details and or input files to replicate the same issue on our end, to verify.</li>
                                </ul>
                                <br />
                                <p>
                                    Above mentioned terms may subject to change with or without prior or after notification.
                                </p>

                            </div>


                        }
                    />

                    <ContractBox


                        title="Privacy Policy"
                        icon="bi bi-shield-check"
                        txt={

                            <div className="overflow-auto h-full p-5">
                                <p>
                                    KaslaanSoft (or KaslaanSoft.com, referred as "Developer") is in legal binding to the below Privacy Policy. This Privacy Policy is formulated to declare clearly all the possible places where you may enter data, whether it is personal, non-personal, or data created when using Developer's website or any software published by it. And that how it is used, analyzed, or kept.
                                </p>
                                <br />
                                <h2 className="font-medium">KaslaanSoft.com</h2>
                                <br />
                                <p>
                                    This section describes subject declarations specific to using KaslaanSoft website.
                                </p>
                                <br />
                                <p>
                                    This website lists all the software developed and published by KaslaanSoft.com. This website does not host any setups. Direct links are provided to the associated Microsoft App Store page links, where the software may be bought, or evaluated, depending on whether it is paid or trial version respectively; or install free if it is freeware.
                                </p>
                                <br />
                                <p>
                                    Developer does not collect any visitor's data, in any way, and for any purpose. But it may be cached, used, analyzed, or stored in any way, by the web browser, visitor is using. Developer has no connection with it. Same applies to when following external links.
                                </p>
                                <br />
                                <h2 className="font-medium">KaslaanSoft.com Products</h2>
                                <br />
                                <p>
                                    This section describes subject declarations specific to using KaslaanSoft Products.
                                </p>
                                <br />
                                <p>
                                    Developer does not collect, store, or analyze any user data, whether it is personal, non-personal, or data created when using any product published by the Developer. Neither we transfer it to third party. All the software are designed to work fully offline. And have zero dependency on internet connection. But when providing Software Install Id, (which is used to verify specific product installation, for getting new versions of any paid product, for free, when available) through email, Developer will store sent Software Install Id with sender email address. User can request to delete it, but sending new versions of the product will become impossible, for which user has provided Software Install Id.
                                </p>
                                <br />
                                <p>
                                    Above mentioned terms may subject to change with or without prior or after notification.
                                </p>

                            </div>


                        }
                    />
                </div>
                <div className="flex items-center justify-center">
                    <label className="ml-2 text-center cursor-pointer flex items-start">
                        <input type="checkbox" className="relative mr-2 top-[6px] cursor-pointer" onChange={handleChange} />
                        <span className="align-top text-justify">I confirm, I have read and agreed to the above specified License Terms and Privacy Policy</span>
                    </label>
                </div>


            </div>

        </div>

    )
}

export default Contract