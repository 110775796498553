import ArrowDown from "../assets/down_arrow.svg"
import React, { useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import ContactBg from "../assets/contact_bg.svg"
import { useInView } from 'react-intersection-observer'

function Contact({ name }) {
    const fileInputRef = useRef(null)
    const [fileName, setFileName] = useState("")
    const [isVerified, setIsVerified] = useState(false)

    const handleButtonClick = () => {
        fileInputRef.current.click()
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            setFileName(file.name); // Update the state with the selected file name
        }
    }

    const handleCaptchaChange = (value) => {
        if (value) {
            setIsVerified(true); // CAPTCHA is completed
        } else {
            setIsVerified(false); // CAPTCHA is not completed
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isVerified) {
            alert("Form submitted successfully!")
        } else {
            alert("Please complete the reCAPTCHA.")
        }
    }

    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    return (

        <div className="overflow-x-hidden">
            <div className={`h-auto w-full pb-28 xl:pl-24 pl-0 relative transition-all duration-1000 ease-in-out ${inView ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-full'}`}
                style={{ background: 'linear-gradient(to top right, #e0e0e0, #e7e7e7, #f6f6f6, #fafafa, #fdfdfd)' }}
                ref={ref}
                name={name}
            >
                <img src={ContactBg} alt="contact-bg" className="h-full py-10 opacity-5 absolute right-20"></img>
                <h1 className="text-xl pt-7 pb-10 text-center">
                    <i className="bi bi-envelope relative bottom-0.5 mr-2"></i>Contact
                </h1>

                <div className="flex justify-center xl:flex-row flex-col xl:items-start items-center xl:px-0 lg:px-10 px-5 z-10">
                    {/* Left Column */}
                    <div className="xl:w-1/3 w-full z-10 flex mx-5 justify-between flex-col h-full">
                        <h2 className="text-red-500 mb-6 font-medium text-xl">Details</h2>
                        <div className="flex justify-center items-center flex-col">
                            <div className="h-[155px] w-full flex flex-col">
                                <div className="h-1/3 mb-2 flex" style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                                    <label className="bg-[#B3EAFF] w-32 pl-5 text-gray-600 flex items-center justify-start">
                                        Name:
                                    </label>
                                    <input
                                        type="text"
                                        className="outline-none w-full h-full text-left font-medium px-5 bg-[#B3EAFF]"
                                    />
                                </div>

                                <div className="h-1/3 mb-2 flex" style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                                    <label className="bg-[#B3EAFF] w-32 pl-5 text-gray-600  flex items-center justify-start">
                                        Email:
                                    </label>
                                    <input
                                        type="text"
                                        className="outline-none w-full h-full text-left font-medium px-5 bg-[#B3EAFF]"
                                    />
                                </div>
                                <div className="h-1/3 flex  relative" style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                                    <label className="bg-[#B3EAFF] text-gray-600 text-center flex w-32 items-center pl-5 justify-start">
                                        Subject:
                                    </label>
                                    <select
                                        name=""
                                        id=""
                                        className="w-full outline-none bg-[#6BD6FF] pl-5 font-medium  appearance-none"
                                    >
                                        <option>Feedback</option>
                                        <option>Suggestion</option>
                                        <option>Business inquiry</option>
                                    </select>
                                    <span className="pointer-events-none absolute inset-y-0 right-5 top-1.5 flex items-center">
                                        <img src={ArrowDown} alt="arrow" className="h-6" />
                                    </span>
                                </div>
                            </div>
                            <div className=" h-[155px]  w-full ">
                                <h2 className="text-red-500 mt-3 mb-3 font-medium text-xl">
                                    Attachment
                                </h2>
                                <div className="h-1/3 mb-2 flex" style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                                    <label className="bg-[#B3EAFF] w-32  text-gray-600 flex pl-5 items-center justify-start">
                                        File:
                                    </label>
                                    <input
                                        type="text"
                                        value={fileName}
                                        readOnly
                                        className="pl-5 bg-[#B3EAFF] font-medium outline-none w-full "
                                        placeholder="No file selected"
                                    />
                                </div>
                                <div className="flex items-center justify-center">
                                    <input

                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <button
                                        className="text-white mt-2 bg-[#00adef] hover:bg-[#0DBEFE] active:translate-y-0.5 px-7 py-1"
                                        onClick={handleButtonClick}
                                    >
                                        Select File
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Middle Column */}
                    <div className="xl:w-1/3 w-full  flex mx-5 z-10 justify-between flex-col">
                        <h2 className="text-red-500 mb-6 font-medium text-xl">Message</h2>
                        <textarea
                            name="3"
                            id=""
                            className="w-full resize-none shadow-lg  bg-[#b3eaff] outline-none p-5"
                            style={{ height: "295px", boxShadow: "0px 17px 5px rgba(0, 0, 0, 0.5)" }}
                        ></textarea>
                        <div className="bg-[#b3eaff] h-[15px]">

                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="xl:w-1/3 w-full h-full z-10 flex mx-5 flex-col justify-start xl:justify-center items-center xl:items-start">
                        <h2 className="text-red-500 xl:mt-0 mt-7 mb-6 font-medium text-xl">Send</h2>

                        {/* reCAPTCHA and Submit Button */}
                        <div className="flex flex-col mt-2 ">
                            <ReCAPTCHA
                                sitekey="6LfHAG4mAAAAADD3zSWnLJG0yD70gIPCxW-LgJ8j"
                                onChange={handleCaptchaChange}
                            />

                            <div className="flex xl:justify-center justify-center">
                                <button
                                    className={`mt-6 w-32 py-1 text-white ${isVerified ? "bg-[#00adef] hover:bg-[#0DBEFE] active:translate-y-0.5" : "bg-gray-500 cursor-not-allowed"
                                        }`}
                                    disabled={!isVerified}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Contact
