
import contact_1 from "../assets/contact_1.png"
import contact_2 from "../assets/contact_2.png"
import contact_3 from "../assets/contact_3.png"

function Footer() {

    const currentYear = new Date().getFullYear()

    return (
        <div className="flex justify-center items-center bg-[#d2d2d2] flex-col">
            <div className="flex mt-10 justify-start lg:pl-20 pl-0 w-full items-center lg:items-start flex-col">
                <p>
                    Address:
                </p>
                <p>
                    House 5, Street 1, Sadiq Akbar Colony, Landay Sarak,
                </p>
                <p>
                    Charsadda Rd, Peshawar, KPK, Pakistan
                </p>
            </div>
            <div className="flex mt-10 mb-5 justify-start lg:pl-20 pl-0 w-full items-center lg:items-start flex-col">
                <p>
                    Useful links:
                </p>
                <div className="flex items-center">
                    <img src={contact_1} alt="" className="h-4 mr-2" />
                    <a href="https://www.google.com" target="_blank" rel="noopener noreferrer" className="hover:underline">
                        Kaslaan YouTube Channel
                    </a>
                    <i class="bi bi-arrow-up-right"></i>
                </div>

                <div className="flex items-center">
                    <img src={contact_2} alt="" className="h-4 mr-2" />
                    <a href="https://www.google.com" target="_blank" rel="noopener noreferrer" className="hover:underline">
                        Developer's website
                    </a>
                    <i class="bi bi-arrow-up-right"></i>
                </div>
                <div className="flex items-center">
                    <img src={contact_3} alt="" className="h-4 mr-2" />
                    <a href="https://www.google.com" target="_blank" rel="noopener noreferrer" className="hover:underline">
                        KaslaanSoft at Microsoft Store
                    </a>
                    <i class="bi bi-arrow-up-right"></i>
                </div>
            </div>
            <div className="h-10 mb-3 flex justify-center items-center text-sm">
                <p>© 2020-{currentYear} KaslaanSoft All Rights Reserved</p>
            </div>
        </div>

    )

}

export default Footer