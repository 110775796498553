import React, { useState, useEffect } from 'react';

function AppPreview({ images }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageOpacity, setImageOpacity] = useState(100); // State for controlling image opacity

    // Function to go to the previous image
    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
        startFade(newIndex); // Trigger fade effect
    };

    // Function to go to the next image
    const goToNext = () => {
        const isLastSlide = currentIndex === images.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        startFade(newIndex); // Trigger fade effect
    };

    // Function to go to a specific slide and start the fade effect
    const goToSlide = (index) => {
        startFade(index); // Trigger fade effect
    };

    // Function to manage the fade effect
    const startFade = (newIndex) => {
        setImageOpacity(0); // Fade out the current image

        setTimeout(() => {
            setCurrentIndex(newIndex); // Change to the new index
            setImageOpacity(100); // Fade in the new image
        }, 1000); // Duration matches the transition time
    };

    // Auto-slide effect every 3 seconds
    useEffect(() => {
        const interval = setInterval(goToNext, 4000)
        return () => clearInterval(interval)
    }, [currentIndex])

    return (
        <div className="relative flex flex-col items-center">
            <div className="relative flex justify-center items-center sm:w-[500px] w-full h-[350px] overflow-hidden bg-transparent">
                <button
                    onClick={goToPrevious}
                    className="text-black text-2xl cursor-pointer z-2"
                >
                    ❮
                </button>

                <div className="flex mx-1 justify-center items-center w-full h-full">
                    <img
                        src={images[currentIndex]}
                        alt={`Slide ${currentIndex}`}
                        className="max-w-full max-h-full object-contain bg-[#f0f0f0] transition-opacity duration-1000 ease-in-out"
                        style={{ opacity: imageOpacity }} // Set opacity based on state
                    />
                </div>

                <button
                    onClick={goToNext}
                    className="text-black text-2xl cursor-pointer z-2"
                >
                    ❯
                </button>
            </div>

            <div className="flex justify-center items-center mt-2">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={`h-3 w-3 mx-2 rounded-full cursor-pointer transition-colors duration-300 
                            ${index === currentIndex ? 'bg-black border-none' : 'bg-transparent border-2 border-black'}`}
                        onClick={() => goToSlide(index)} // Change image on dot click
                    ></span>
                ))}
            </div>
        </div>
    );
}

export default AppPreview;
