
function Button ({txt, icon=false, targetLink, acceptedPolicy}) {

    return (
        <>
              {icon ? <button onClick={() => {alert("This section will soon be available.")}} className="bg-[#00ADEF] border-2 mx-1 border-[#078EBE] text-white font-medium px-2 py-1 sm:w-36 w-48 sm:my-0 my-1 hover:bg-[#10BDFF] active:-translate-y-0.5">{txt} <i className={icon} style={{position: "relative", bottom: "1px", marginLeft: "4px"}}></i> </button> : <button className="bg-[#00ADEF] border-2 mx-1 border-[#078EBE] text-white font-medium px-6 py-1 sm:w-36 w-48  sm:my-0 my-1  hover:bg-[#10BDFF] active:-translate-y-0.5" onClick={() => {
                if (acceptedPolicy) {
                    window.open(targetLink, '_blank');
                } else {
                    alert('Make sure you read and accept our License Terms and Privacy Policy stated below.')
                }
                
              }}>{txt}</button>}
        </> 
        
    )
}

export default Button