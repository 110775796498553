
import React, { useState, useRef, useEffect } from 'react'
import ArrowDown from "../assets/down_arrow.svg"
import KsLogoFull from "../assets/ks_logo_full.png"
import KsLogo from "../assets/ks_logo.png"
import { Link } from "react-scroll"

function NavBar() {


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 640)
        }

        window.addEventListener('resize', handleResize)

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const menuRef = useRef(null)

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }

    }, [menuRef])

    return (
        <div className="sm:h-20 h-[72px] bg-[#E1E1E1] shadow-md shadow-gray-400 flex justify-center z-20 fixed top-0 left-0 w-full">
            <div className="w-1/2  flex xl:justify-around lg:ml-10 md:ml-10 ml-2 sm:justify-start justify-between items-center">
                <div>
                    {isMobile ? <img src={KsLogo} alt="kaslaan logo" className='sm:h-[66px] h-[58px]' /> : <img src={KsLogoFull} alt="kaslaan logo" className='h-[66px]' />}
                </div>
                <div className="relative" ref={menuRef}>
                    <button
                        className="font-semibold cursor-pointer outline-none border-b-2 hover:border-b-2 border-transparent hover:border-black transition-all duration-150 ease-in-out lg:ml-28 ml-5 sm:ml-5 4xl:ml-16 3xl:ml-5 xs:ml-20 "
                        onClick={toggleMenu}
                    >
                        <div className='whitespace-nowrap'>
                            Products
                            {<img src={ArrowDown} alt="Arrow" className="inline-block ml-2 w-4 h-4 relative -top-0.5 " />}
                        </div>

                    </button>


                    {isMenuOpen && (
                        <ul className="absolute left-0 top-12 mt-2 w-56 bg-white rounded-lg shadow-lg">
                            <li className="flex cursor-pointer" >
                                <Link className="bg-white px-4 py-2 hover:bg-gray-200 w-full" to="kaslaan-macros" spy={true} smooth={true} offset={-100} duration={1000} onClick={() => { setIsMenuOpen(false) }} >Kaslaan Macros</Link>
                            </li>
                            <li className="flex cursor-pointer" >
                                <Link className="bg-white px-4 py-2 hover:bg-gray-200 w-full" to="kaslaan-doc" spy={true} smooth={true} offset={-80} duration={1000} onClick={() => { setIsMenuOpen(false) }}>Kaslaan DocConvert</Link>
                            </li>
                            <li className="flex cursor-pointer" >
                                <Link className="bg-white px-4 py-2 hover:bg-gray-200 w-full" to="kaslaan-media" spy={true} smooth={true} offset={-80} duration={1000} onClick={() => { setIsMenuOpen(false) }}>Kaslaan MediaConverter</Link>
                            </li>
                            <li className="flex cursor-pointer" >
                                <Link className="bg-white px-4 py-2 hover:bg-gray-200 w-full" to="kaslaan-bmi" spy={true} smooth={true} offset={-80} duration={1000} onClick={() => { setIsMenuOpen(false) }}>Kaslaan BMI</Link>
                            </li>
                            <li className="flex cursor-pointer" >
                                <Link className="bg-white px-4 py-2 hover:bg-gray-200 w-full" to="kaslaan-inspect" spy={true} smooth={true} offset={-80} duration={1000} onClick={() => { setIsMenuOpen(false) }}>Kaslaan SizeInspect</Link>
                            </li>
                            <li className="flex cursor-pointer" >
                                <Link className="bg-white px-4 py-2 hover:bg-gray-200 w-full" to="kaslaan-calc" spy={true} smooth={true} offset={-80} duration={1000} onClick={() => { setIsMenuOpen(false) }}>Kaslaan Calculator</Link>
                            </li>
                            <li className="flex cursor-pointer" >
                                <Link className="bg-white px-4 py-2 hover:bg-gray-200 w-full" to="kaslaan-hasher" spy={true} smooth={true} offset={-80} duration={1000} onClick={() => { setIsMenuOpen(false) }}>Kaslaan Hasher</Link>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
            <div className="w-1/2 flex justify-end items-center">
                <ul className="justify-center items-center flex font-semibold whitespace-nowrap">
                    <li className="nav-item">
                        <Link to="license-terms" spy={true} smooth={true} offset={-80} onClick={() => { setIsMenuOpen(false) }}>
                            {isMobile ? <i class="bi bi-vector-pen text-2xl relative -top-0.5"></i> : 'License Terms'}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="license-terms" spy={true} smooth={true} offset={isMobile ? 650 : -80} onClick={() => { setIsMenuOpen(false) }}>
                            {isMobile ? <i class="bi bi-shield-check text-2xl relative -top-0.5"></i> : 'Privacy Policy'}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="contact" spy={true} smooth={true} offset={-80} onClick={() => { setIsMenuOpen(false) }}>
                            {isMobile ? <i class="bi bi-envelope text-2xl relative -top-0.5"></i> : 'Contact'}
                        </Link>
                    </li>
                </ul>

                <i
                    className="bi bi-cart2 xl:mr-16 md:mr-10 mr-2 xl:ml-28 md:ml-10 ml-2 text-2xl relative bottom-[3px] transition-all duration-150 ease-in-out cursor-pointer hover:text-gray-600"
                ></i>

            </div>
        </div>
    )
}

export default NavBar