
import NavBar from "./components/NavBar"
import AppSlide from "./components/AppSlide"
import Contract from "./components/Contract"
import Footer from "./components/Footer"
import Intro from "./components/Intro"

import "./index.css"

import React, { useState, useEffect } from 'react'
import Contact from "./components/Contact"


function App() {

    const [privacyStatus, setPrivacyStatus] = useState(false)

    function handleStatus(checked) {
        setPrivacyStatus(checked)
    }

    const ks_bmi_logo = "./assets/ks-bmi/ks_bmi_logo.svg"
    const ks_bmi_imgs = ["./assets/ks-bmi/ks_bmi_1.png", "./assets/ks-bmi/ks_bmi_2.png", "./assets/ks-bmi/ks_bmi_3.png"]

    const ks_doc_logo = "./assets/ks-doc/ks_doc_logo.svg"
    const ks_doc_imgs = ["./assets/ks-doc/ks_doc_1.png", "./assets/ks-doc/ks_doc_2.png", "./assets/ks-doc/ks_doc_3.png", "./assets/ks-doc/ks_doc_4.png", "./assets/ks-doc/ks_doc_5.png"]

    const ks_calc_logo = "./assets/ks-calc/ks_calc_logo.svg"
    const ks_calc_imgs = ["./assets/ks-calc/ks_calc_1.png", "./assets/ks-calc/ks_calc_2.png", "./assets/ks-calc/ks_calc_3.png", "./assets/ks-calc/ks_calc_4.png", "./assets/ks-calc/ks_calc_5.png", "./assets/ks-calc/ks_calc_6.png"]

    const ks_media_logo = "./assets/ks-media/ks_media_logo.svg"
    const ks_media_imgs = ["./assets/ks-media/ks_media_1.png", "./assets/ks-media/ks_media_2.png", "./assets/ks-media/ks_media_3.png", "./assets/ks-media/ks_media_4.png"]

    const ks_hasher_logo = "./assets/ks-hasher/ks_hasher_logo.svg"
    const ks_hasher_imgs = ["./assets/ks-hasher/ks_hasher_1.png", "./assets/ks-hasher/ks_hasher_2.png", "./assets/ks-hasher/ks_hasher_3.png", "./assets/ks-hasher/ks_hasher_4.png"]

    const ks_macros_logo = "./assets/ks-macros/ks_macros_logo.svg"
    const ks_macros_imgs = ["./assets/ks-macros/ks_macros_1.png", "./assets/ks-macros/ks_macros_2.png", "./assets/ks-macros/ks_macros_3.png", "./assets/ks-macros/ks_macros_4.png", "./assets/ks-macros/ks_macros_5.png", "./assets/ks-macros/ks_macros_6.png"]

    const ks_inspect_logo = "./assets/ks-inspect/ks_inspect_logo.svg"
    const ks_inspect_imgs = ["./assets/ks-inspect/ks_inspect_1.png", "./assets/ks-inspect/ks_inspect_2.png", "./assets/ks-inspect/ks_inspect_3.png"]

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1280)
        }

        window.addEventListener('resize', handleResize)

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <>
            <NavBar />

            <p className="sm:mt-20 mt-16"></p>
            
            <Intro />

            <AppSlide name="kaslaan-macros" screenSize={isMobile} imgs={ks_macros_imgs} title="Kaslaan Macros" logo={ks_macros_logo} desc="Automate your keyboard and mouse in a flexible way. Also have additional commands related to filesystem, system power, webpage access, etc. Custom macros are created, which are populated by adding commands selected from a list of categories; or user can record his or her steps with dedicated Records Steps button. User design a macro to automate his certain task he do with his hands." bgColor="#FDE8DE" lineColor="#F26522" price="$9.99" direction="left"
             links={{'cart': "https://www.microsoft.com/en-us/p/kaslaan-macros/9n9nv9nwzd8k", 'free': 'https://www.microsoft.com/en-us/p/kaslaan-macros-free-trial/9nm5k28vjhnt'}} privacy={privacyStatus}
              />
            <AppSlide name="kaslaan-doc" screenSize={isMobile} imgs={ks_doc_imgs} title="Kaslaan DocConvert" logo={ks_doc_logo} desc="Want to convert a folder of DOCX files to PDF files, with a single click, without even any need of Microsoft Word? Or want to convert all spreadsheets in every Microsoft Excel file, in a folder, with a single click, without even any need of Microsoft Excel? Or want to watermark all PDFs files in a folder with a single click? I know, it does not ends here. Try Kaslaan DocConvert, which uses multi-threaded approach to deliver extra faster processing speeds, designed to be all-in-one batch document converting and processing utility. That could sit in your office or home computer, and make your life a bit easier." bgColor="#D9F7EF" lineColor="#00CA94" price="$9.99" direction="right"
            links={{'cart': "https://apps.microsoft.com/store/detail/kaslaan-docconvert/9NQ4MHT6V0HN", 'free': 'https://apps.microsoft.com/store/detail/kaslaan-docconvert-free-trial/9NWLKCWVM9BZ'}} privacy={privacyStatus}
             />
            <AppSlide name="kaslaan-media" screenSize={isMobile} imgs={ks_media_imgs} title="Kaslaan MediaConverter" logo={ks_media_logo} desc="Multi-threaded, all in one media file type converter and processor. It supports several file types of image, video, and audio; and supports conversion in between. Most of conversions offer several settings. It will automatically detect what files could be converted/processed in user selected folder, and will list options accordingly. It is important that files already exist in source folder, before specifying; otherwise Reload button should be pressed. Hitting start button will start the process, and it will use multi-threaded approach to deliver extra fast processing speeds - it processes multiple files in parallel." bgColor="#FDE7F1" lineColor="#EF5EA1" price="$9.99" direction="left"
            links={{'cart': "https://www.microsoft.com/en-us/p/kaslaan-mediaconverter/9P7Q1S918WCV", 'free': 'https://www.microsoft.com/en-us/p/kaslaan-mediaconverter-free-trial/9PFF1245MT9C'}} privacy={privacyStatus}
             />
            <AppSlide name="kaslaan-bmi" screenSize={isMobile} imgs={ks_bmi_imgs} title="Kaslaan BMI" logo={ks_bmi_logo} desc="Ground-up rebuild of original Kaslaan BMI. Bringing you more features and more reliable user experience. Can now find BMI category of non-adults, male and female, of age 2 to 20 years, using Percentile graphs, published by CDC (cdc.gov). Now can track BMI in better way and for multiple users." bgColor="#FEF3E4" lineColor="#FBB04C" price="$9.99" direction="right"
            links={{'cart': "https://www.microsoft.com/en-us/p/kaslaan-bmi/9NKPR8DJWHR3", 'free': 'https://www.microsoft.com/en-us/p/kaslaan-bmi-free-trial/9PMJ3SP4TZZM'}} privacy={privacyStatus}
             />
            <AppSlide name="kaslaan-inspect" screenSize={isMobile} imgs={ks_inspect_imgs} title="Kaslaan SizeInspect" logo={ks_inspect_logo} desc="Generate comprehensive report of disk usage of a folder content. Get knowledge of what files are affecting disk usage the most. User selected folder is analyzed including all subfolders, and report is generated. Report can be configured by a number of options, prior to running the process. Individual report column can be hide or shown by the user. Each item in the report can be double-clicked to open. On right-clicking, brings a menu with few useful options. Generated report can be exported to local drive. Report results can also be sorted in Ascending and Descending order (based on Portion column)." bgColor="#fAE6FF" lineColor="#C700FF" price="$9.99" direction="left"
            links={{'cart': "https://www.microsoft.com/en-us/p/kaslaan-sizeinspect/9pf6js85tlx6", 'free': 'https://www.microsoft.com/en-us/p/kaslaan-sizeinspect-free-trial/9n9b4ffq4bdr'}} privacy={privacyStatus}
             />
            <AppSlide name="kaslaan-calc" screenSize={isMobile} imgs={ks_calc_imgs} title="Kaslaan Calculator" logo={ks_calc_logo} desc="This is an advanced modern general purpose calculator with also some minimal scientific calculations support, intended to be used at any place whether it is at home, school, office or at any other place. This is not a single calculator but can be called as a (or an important) set of calculators because it consist of many calculators. A portable solution for daily life numerical tasks/problems." bgColor="#D9F3FD" lineColor="#00AEEF" price="$9.99" direction="right"
            links={{'cart': "https://www.microsoft.com/en-us/p/kaslaan-calculator/9phpd49mtb0v", 'free': 'https://www.microsoft.com/en-us/p/kaslaan-calculator-free-trial/9p0jl7lbgpfm'}} privacy={privacyStatus}
             />
            <AppSlide name="kaslaan-hasher" screenSize={isMobile} imgs={ks_hasher_imgs} title="Kaslaan Hasher" logo={ks_hasher_logo} desc="Find checksum of text, file, or even folder with ease. It uses its own algorithm to treat folder just like a file. All popular checksum algorithms are supported. It process data from start till end which means it will hint even if a single byte has been changed in a text/file/folder. Now you can verify your data integrity in a flexible and most secure way." bgColor="#FCDDDE" lineColor="#ED1C24" price="$9.99" direction="left"
            links={{'cart': "https://www.microsoft.com/en-us/p/kaslaan-hasher/9mtj7nvqml23", 'free': 'https://www.microsoft.com/en-us/p/kaslaan-hasher-free-trial/9n5895g05w57'}} privacy={privacyStatus}
             />

            <Contract name="license-terms" changeStatus={handleStatus} />

            <Contact name="contact" />

            <Footer />
        </>

    )
}

export default App