
import { useInView } from 'react-intersection-observer'

function Intro() {

    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    return (
        <div
            ref={ref}
            className={`text-center relative transition-opacity duration-[2s] ease-in-out lg:px-32 px-5 sm:py-16 py-5 ${inView ? 'opacity-100' : 'opacity-0'}`}
        >
            <h2 className="text-2xl font-medium mb-5">
                KaslaanSoft - Make your office or studio desk easy!
            </h2>
            <p className="">
                At KaslaanSoft, we understand the importance of maximizing efficiency when using your computer. We all aim to optimize our workspaces, saving time and energy in the process. That's why I, Ishaq, have dedicated extra thought to developing innovative applications designed to enhance your daily tasks like never before.
            </p>
            <br></br>
            <p className="">
                Each application is crafted with a focus on functionality, efficiency, and intuitive design, offering a powerful solution to improve your workflow. Explore the unique tools available at KaslaanSoft, try them for free, and if they bring value to your routine, feel free to make them a permanent part of your toolkit.
            </p>
        </div>
    )
}

export default Intro