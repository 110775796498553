import Button from "./Button";
import AppPreview from "./AppPreview";
import React from 'react';
import { useInView } from 'react-intersection-observer'

function AppSlide({ name, screenSize, imgs, title, logo, desc, bgColor, lineColor, price, direction, links, privacy}) {
    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    const animateDirection = direction === "left" ? "-translate-x-full" : "translate-x-full";

    const images = imgs

    return (
        <>
            {screenSize ? (
                <div className="h-auto overflow-x-hidden">
                    <div
                        name={name}
                        ref={ref}
                        style={{ backgroundColor: bgColor }}
                        className={`py-10 px-5 flex flex-col mb-10 items-center justify-between relative transition-all duration-1000 ease-in-out h-full ${inView ? 'opacity-100 translate-x-0' : `opacity-0 ${animateDirection}`}`}
                    >
                        <div className="w-full">
                            <div className="flex items-center justify-start mb-5">
                                <img src={logo} alt="bmi logo" className="h-16 pr-5" style={{ filter: "drop-shadow(0px 0px 5px rgba(0,0,0,.5))" }} />
                                <div className="relative w-full">
                                    <p className="inline-block font-medium text-xl">{title}</p>
                                    <span className="absolute left-0 right-0 -bottom-2 h-[2px]" style={{ backgroundColor: lineColor }}></span>
                                </div>
                            </div>
                            <AppPreview images={images} />
                            <div className="pt-5 pb-5 sm-pb-0 mt-5 h-48 overflow-auto border-2 border-gray-300 px-2">
                                {desc}
                            </div>
                        </div>
                        <div className="flex flex-col w-full mt-10">
                            <div className="flex sm:flex-row flex-col justify-between items-center">
                                <div className="flex justify-center items-center sm:flex-row flex-col">
                                    <Button txt="Add to Cart" targetLink={links.cart} acceptedPolicy={privacy} />
                                    <Button txt="Try for Free" targetLink={links.free} acceptedPolicy={privacy}  />
                                </div>
                                <div>
                                    <Button txt="Learn More" icon="bi bi-arrow-right"  />
                                </div>
                            </div>
                            <div className="text-red-600 font-bold sm:text-left text-center mt-2 ml-2">
                                <p>{price} USD</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="h-auto overflow-x-hidden">
                    <div
                        name={name}
                        ref={ref}
                        className={`flex justify-start mb-10 items-start h-[500px] relative transition-all duration-1000 ease-in-out ${inView ? 'opacity-100 translate-x-0' : `opacity-0 ${animateDirection}`}`}
                        style={{ backgroundColor: bgColor }}
                    >
                        <div className="w-2/3 py-10 pl-10 sm:pl-20 h-full flex justify-start items-center">
                            <AppPreview images={images} />
                        </div>
                        <div className="py-10 w-full pl-5 sm:pl-10 pr-5 sm:pr-20 flex flex-col justify-between h-full">
                            <div>
                                <div className="flex items-center justify-start">
                                    <img src={logo} alt="bmi logo" className="h-16 pr-5" style={{ filter: "drop-shadow(0px 0px 5px rgba(0,0,0,.5))" }} />
                                    <div className="relative w-full">
                                        <p className="inline-block font-medium text-xl">{title}</p>
                                        <span className="absolute left-0 right-0 -bottom-2 h-[2px]" style={{ backgroundColor: lineColor }}></span>
                                    </div>
                                </div>
                                <div className="pt-5 h-48 w-full overflow-auto ">
                                    {desc}
                                </div>
                            </div>
                            <div className="flex flex-col w-full ">
                                <div className="flex sm:flex-row flex-col justify-between items-center">
                                    <div className="flex justify-center items-center sm:flex-row flex-col">
                                        <Button txt="Add to Cart" targetLink={links.cart}  acceptedPolicy={privacy} />
                                        <Button txt="Try for Free" targetLink={links.free} acceptedPolicy={privacy} />
                                    </div>
                                    <div>
                                        <Button txt="Learn More" icon="bi bi-arrow-right" />
                                    </div>
                                </div>
                                <div className="text-red-600 font-bold mt-2 ml-2">
                                    <p>{price} USD</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AppSlide;
